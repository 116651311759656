// @flow
import React, { useEffect, useCallback, memo, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notifier, Actions as NotificationsActions } from '@dt/notifications';
import { entries } from 'lodash/fp';
import { makeStyles } from '@material-ui/styles';
import { Router, Redirect, useLocation } from '@reach/router';
import {
  NotFoundPage,
  OnboardingBanner as OnboardingBannerComponent,
} from '@dt/components';
import { palette } from '@dt/theme';
import { default as ConfigurableLHSMenuComponent } from '@dt/material-components/navigation/ConfigurableLHSMenuPage';
import {
  MenuWeb,
  MenuMobile,
  MenuSupplyChainSecurity,
  MenuApi,
  MenuCloud,
  MenuDashboard,
  MenuManagement,
} from '@dt/material-components/navigation/ConfigurableLHSMenuConfiguration';
import { useSession } from './session';
import {
  accessControlsApi,
  accessControlsShare,
  accessControlsLandingPage,
} from './redux/access_controls';
import type { State } from './redux/store_state_type';

// prettier-ignore
const {
  ApiInventoryPage,
  ApiAssetGroupsPage,
  ApiAssetGroupsUuidPage,
  AsmSetupPage,
  CloudActivityPage,
  CloudPage,
  CloudAssetGroupsPage,
  CloudInventoryPage,
  CloudPoliciesPage,
  CloudPoliciesUuidPage,
  CloudPolicyViolationsPage,
  CloudPolicyViolationsUuidPage,
  CloudCloudResourcesUuidPage,
  CloudRestfulApisUuidPage,
  CloudNetworkServicesUuidPage,
  CloudToolkitsPage,
  CloudToolkitsCodeRedPage,
  CloudToolkitsLeaksPage,
  CloudToolkitsGotchasPage,
  CloudWebApplicationsUuid,
  CloudAssetGroupsUuidPage,
  WebPolicyViolationsPage,
  WebPolicyViolationsUuidPage,
  WebPage,
  WebAssetGroupsPage,
  WebInventoryPage,
  WebSecurityToolkitsPage,
  WebToxicTokensPage,
  WebSecurityToolkitsXssProtectionPage,
  WebApplicationsUuidPage,
  DashboardPage,
  WebNetworkServicesUuidPage,
  WebCloudResourcesUuidPage,
  WebGraphqlApisUuidPage,
  WebSecurityToolkitsDetectInjectPage,
  WebRestfulApisUuidPage,
  WebPoliciesPage,
  WebPoliciesUuidPage,
  WebAssetGroupsUuidPage,
  WebSecurityToolkitsXssAttackPage,
  ManagementConfigurationsPage,
  ApiPoliciesPage,
  ApiPoliciesUuidPage,

  // {{ TODO: These need to be broken out into separate pages.
  ApolloSecurityDashboardContainer,
  ApolloPolicyViolationDetailsContainer,
  ApolloHackExtractContainer,
  ApolloLeakyApisContainer,
  ApolloDetectInjectContainer,
  ApolloCorrectAndProjectContainer,
  ApolloPolicyRuleWithViolationsAndDetails,
  ApolloRestfulApiDetailsContainer,
  ApolloCloudResourceDetailsContainer,
  ApolloNetworkServiceDetailsContainer,
  ApolloWebApplicationDetailsContainer,
  ApolloApiOperationDetailsContainer,
  ApolloOverview,
  ApolloOverviewDetail,
  ApolloActivityDashboardContainer,
  ApolloApiProtectContainer,
  ApolloSsrfSploitsContainer,
  ApolloAlertsIntegrations,
  ApolloSharedLinks,
  ApolloShadowAssetsContainer,
  // }}

  // {{ TODO: These need to be broken out into separate pages.
  ShareOnboardingPublicView,
  ShareViolationPublicView,
  ShareWebAppPublicView,
  ShareCloudResourcePublicView,
  ShareRestfulAPIPublicView,
  ShareNetworkServicesPublicView,
  ShareProductOnboardingWizardPublicView,
  // }}

  // {{ TODO: These need to be broken out into separate pages.
  ManagementLandingPage,
  ManagementUserInvite,
  ManagementUserEdit,
  ManagementUserDashboard,
  ManagementActivity,
  ManagementProfile,
  ManagementProductOnboardingContainer,
  ManagementVendorManagementContainer,
  ManagementComplianceReports,
  // }}
} = {
  ApiInventoryPage:                           lazy(() => import('./pages/api/inventory/ApiInventoryPage'),),
  ApiAssetGroupsPage:                         lazy(() => import('./pages/api/asset_groups/ApiAssetGroupsPage'),),
  ApiAssetGroupsUuidPage:                     lazy(() => import('./pages/api/asset_groups/uuid/ApiAssetGroupsUuidPage'),),
  AsmSetupPage:                               lazy(() => import('./pages/cloud/asm_setup/AsmSetupPage')),
  CloudActivityPage:                          lazy(() => import('./pages/cloud/activity/CloudActivityPage')),
  CloudPage:                                  lazy(() => import('./pages/cloud/CloudPage')),
  CloudAssetGroupsPage:                       lazy(() => import('./pages/cloud/asset_groups/CloudAssetGroupsPage'),),
  CloudInventoryPage:                         lazy(() => import('./pages/cloud/inventory/CloudInventoryPage')),
  CloudPoliciesPage:                          lazy(() => import('./pages/cloud/policies/CloudPoliciesPage')),
  CloudPoliciesUuidPage:                      lazy(() => import('./pages/cloud/policies/uuid/CloudPoliciesUuidPage')),
  CloudPolicyViolationsPage:                  lazy(() => import('./pages/cloud/policy_violations/CloudPolicyViolationsPage')),
  CloudPolicyViolationsUuidPage:              lazy(() => import('./pages/cloud/policy_violations/uuid/CloudPolicyViolationsUuidPage')),
  CloudCloudResourcesUuidPage:                lazy(() => import('./pages/cloud/cloud_resources/uuid/CloudCloudResourcesUuidPage')),
  CloudNetworkServicesUuidPage:               lazy(() => import('./pages/cloud/network_services/uuid/CloudNetworkServicesUuidPage')),
  CloudRestfulApisUuidPage:                   lazy(() => import('./pages/cloud/restful_apis/uuid/CloudRestfulApisUuidPage')),
  CloudToolkitsPage:                          lazy(() => import('./pages/cloud/toolkits/CloudToolkitsPage')),
  CloudToolkitsCodeRedPage:                   lazy(() => import('./pages/cloud/toolkits/code_red/CodeRedPage')),
  CloudToolkitsLeaksPage:                     lazy(() => import('./pages/cloud/toolkits/leaks/CloudToolkitsLeaksPage')),
  CloudToolkitsGotchasPage:                   lazy(() => import('./pages/cloud/toolkits/gotchas/CloudToolkitsGotchasPage')),
  CloudWebApplicationsUuid:                   lazy(() => import('./pages/cloud/web_applications/uuid/CloudWebApplicationsUuidPage')),
  CloudAssetGroupsUuidPage:                   lazy(() => import('./pages/cloud/asset_groups/uuid/CloudAssetGroupsUuidPage'),),
  WebPolicyViolationsPage:                    lazy(() => import('./pages/web/policy_violations/WebPolicyViolationsPage'),),
  WebPolicyViolationsUuidPage:                lazy(() => import('./pages/web/policy_violations/uuid/WebPolicyViolationsUuidPage'),),
  WebPage:                                    lazy(() => import('./pages/web/WebPage')),
  WebAssetGroupsPage:                         lazy(() => import('./pages/web/asset_groups/WebAssetGroupsPage'),),
  WebInventoryPage:                           lazy(() => import('./pages/web/inventory/WebInventoryPage')),
  WebSecurityToolkitsPage:                    lazy(() => import('./pages/web/security_toolkits/WebSecurityToolkitsPage'),),
  WebToxicTokensPage:                         lazy(() => import('./pages/web/toxic_tokens/WebToxicTokensPage')),
  WebSecurityToolkitsXssProtectionPage:       lazy(() => import('./pages/web/security_toolkits/xss_protection/WebSecurityToolkitsXssProtectionPage'),),
  WebApplicationsUuidPage:                    lazy(() => import('./pages/web/applications/uuid/WebApplicationsUuidPage')),
  DashboardPage:                              lazy(() => import('./pages/dashboard/DashboardPage')),
  WebNetworkServicesUuidPage:                 lazy(() => import('./pages/web/network_services/uuid/WebNetworkServicesUuidPage'),),
  WebCloudResourcesUuidPage:                  lazy(() => import('./pages/web/cloud_resources/uuid/WebCloudResourcesUuidPage'),),
  WebGraphqlApisUuidPage:                     lazy(() => import('./pages/web/graphql_apis/uuid/WebGraphqlApisUuidPage'),),
  WebSecurityToolkitsDetectInjectPage:        lazy(() => import('./pages/web/security_toolkits/sql_injection/WebSecurityToolkitsSqlInjectionPage'),),
  WebRestfulApisUuidPage:                     lazy(() => import('./pages/web/restful_apis/uuid/WebRestfulApisUuidPage')),
  WebPoliciesPage:                            lazy(() => import('./pages/web/policies/WebPoliciesPage'),),
  WebPoliciesUuidPage:                        lazy(() => import('./pages/web/policies/uuid/WebPoliciesUuidPage'),),
  WebAssetGroupsUuidPage:                     lazy(() => import('./pages/web/asset_groups/uuid/WebAssetGroupsUuidPage'),),
  WebSecurityToolkitsXssAttackPage:           lazy(() => import('./pages/web/security_toolkits/xss_attack/WebSecurityToolkitsXssAttackPage')),
  ManagementConfigurationsPage:               lazy(() => import('./pages/management/configurations/ManagementConfigurationsPage'),),
  ApiPoliciesPage:                            lazy(() => import('./pages/api/policies/ApiPoliciesPage'),),
  ApiPoliciesUuidPage:                        lazy(() => import('./pages/api/policies/uuid/ApiPoliciesUuidPage'),),

  // {{ TODO: These need to be broken out into separate pages.
  ApolloSecurityDashboardContainer:           lazy(() => import('./redux/policy_violations/PolicyViolationDashboardContainer')),
  ApolloPolicyViolationDetailsContainer:      lazy(() => import('./redux/policy_violations/PolicyViolationDetailsContainer')),
  ApolloHackExtractContainer:                 lazy(() => import('./redux/hack_extract/HackExtractContainer')),
  ApolloLeakyApisContainer:                   lazy(() => import('./redux/leaky_apis/LeakyApisContainer')),
  ApolloDetectInjectContainer:                lazy(() => import('./redux/detect_inject/DetectInjectContainer')),
  ApolloCorrectAndProjectContainer:           lazy(() => import('./redux/correct_protect/CorrectAndProtectContainer')),
  ApolloPolicyRuleWithViolationsAndDetails:   lazy(() => import('./redux/policy_rules/PolicyRuleWithViolationsAndDetails')),
  ApolloRestfulApiDetailsContainer:           lazy(() => import('./redux/restful_apis/RestfulAPIDetailsContainer')),
  ApolloCloudResourceDetailsContainer:        lazy(() => import('./redux/cloud_resources/CloudResourceDetailsContainer')),
  ApolloNetworkServiceDetailsContainer:       lazy(() => import('./redux/network_services/NetworkServiceDetailsContainer')),
  ApolloWebApplicationDetailsContainer:       lazy(() => import('./redux/web_applications/WebApplicationDetailsContainer')),
  ApolloApiOperationDetailsContainer:         lazy(() => import('./redux/api_operations/ApiOperationDetailsContainer')),
  ApolloOverview:                             lazy(() => import('./redux/dashboard/Overview')),
  ApolloOverviewDetail:                       lazy(() => import('./redux/dashboard/OverviewDetail')),
  ApolloActivityDashboardContainer:           lazy(() => import('./redux/events/ActivityDashboardContainer')),
  ApolloApiProtectContainer:                  lazy(() => import('./redux/protect/ApiProtectContainer')),
  ApolloSsrfSploitsContainer:                 lazy(() => import('./redux/ssrf_sploits/SsrfSploitsContainer')),
  ApolloAlertsIntegrations:                   lazy(() => import('./redux/alerts_integrations/AlertsIntegrations')),
  ApolloSharedLinks:                          lazy(() => import('./redux/shared_links/SharedLinks')),
  ApolloShadowAssetsContainer:                lazy(() => import('./redux/shadow_assets/ShadowAssetsContainer')),
  // }}

  // {{ TODO: These need to be broken out into separate pages.
  ShareOnboardingPublicView:                  lazy(() => import('./redux/shared_links/public_app/OnboardingPublicView')),
  ShareViolationPublicView:                   lazy(() => import('./redux/shared_links/public_app/ViolationPublicView')),
  ShareWebAppPublicView:                      lazy(() => import('./redux/shared_links/public_app/WebAppPublicView')),
  ShareCloudResourcePublicView:               lazy(() => import('./redux/shared_links/public_app/CloudResourcePublicView')),
  ShareRestfulAPIPublicView:                  lazy(() => import('./redux/shared_links/public_app/RestfulAPIPublicView')),
  ShareNetworkServicesPublicView:             lazy(() => import('./redux/shared_links/public_app/NetworkServicePublicView')),
  ShareProductOnboardingWizardPublicView:     lazy(() => import('./redux/shared_links/public_app/ProductOnboardingWizardPublicView')),
  // }}

  // {{ TODO: These need to be broken out into separate pages.
  ManagementLandingPage:                      lazy(() => import('@dt/landing-page/LandingPage')),
  ManagementUserInvite:                       lazy(() => import('./redux/users/UserInvite')),
  ManagementUserEdit:                         lazy(() => import('./redux/users/UserEdit')),
  ManagementUserDashboard:                    lazy(() => import('./redux/users/UserDashboard')),
  ManagementActivity:                         lazy(() => import('./redux/activity/Activity')),
  ManagementProfile:                          lazy(() => import('./redux/profile/Profile')),
  ManagementProductOnboardingContainer:       lazy(() => import('./redux/onboarding_wizard/ProductOnboardingContainer')),
  ManagementVendorManagementContainer:        lazy(() => import('./redux/vendor_management/VendorManagementContainer')),
  ManagementComplianceReports:                lazy(() => import('./redux/compliance_reports/ComplianceReports')),
  // }}
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
  },
  content: {
    overflow: 'auto',
    flexGrow: '1',
    minHeight: '100%',
    backgroundColor: ({ pathname }) => {
      // NOTE: HACK: If you add more code here I will find you... and buy you a coffee.

      // TODO: All API Pages need to be updated with the new gray background.
      if (pathname.indexOf('/api') === 0) {
        // Pages that have been ported.
        if (
          pathname.indexOf('/api/share') !== 0 &&
          pathname.indexOf('/api/policies') !== 0 &&
          pathname.indexOf('/api/asset-groups') !== 0 &&
          pathname.indexOf('/api/inventory') !== 0
        ) {
          return palette.white;
        }
      }

      return palette.gray50;
    },
  },
});

/*
 * Wraps the left hand menu with user session information.
 *
 * If no session is found, don't render.
 * This is primarily used to prevent issues for actors with temporary session tokens.
 *
 * TODO: Push down into menu after all apps have been merged.
 *
 * @deprecated - Onboarding banner needs to be moved into this application.
 */
function ConfigurableLHSMenu(props) {
  const { user_account } = useSession({
    // TODO: HACK: This happens because these `/share` pages don't belong to a common ancestor.
    //             These pages need to be moved.
    requireUserAccount: false,
    requireUserSession: false,
  });
  if (!user_account) return <ConfigurableLHSMenuComponent isLoading />;

  // $FlowFixMe - Use the same props.
  return <ConfigurableLHSMenuComponent {...props} />;
}

/*
 * Wraps the onboarding banner with user session information.
 *
 * If no session is found, don't render.
 * This is primarily used to prevent issues for actors with temporary session tokens.
 *
 * TODO: Push down into banner after all apps have been merged.
 *
 * @deprecated - Onboarding banner needs to be moved into this application.
 */
function OnboardingBanner() {
  const { user_account } = useSession({
    requireUserAccount: false,
    requireUserSession: false,
  });
  if (!user_account) return null;

  return (
    <OnboardingBannerComponent
      accountInfo={user_account.accountInfo}
      fullScreen
    />
  );
}

function ApplicationRouting() {
  // TODO: Should *not* be checking path outside of the router.
  const location = useLocation();
  const pathname = location.pathname;

  const styles = useStyles({ pathname });

  const dispatch = useDispatch();

  // TODO: Remove this in favor of pushing the logic down into the Notifier component.
  const dispatchDismissNotificationClicked = useCallback(
    notification => {
      dispatch(NotificationsActions.dismissNotificationClicked(notification));
    },
    [dispatch],
  );
  const notifications = useSelector<State, _>(
    ({ notifications }) => notifications,
  );

  // Page Title.
  useEffect(() => {
    let title = null;
    if (pathname.startsWith('/api')) {
      title = 'API Secure';
    } else if (pathname.startsWith('/cloud')) {
      title = 'Cloud Secure';
    } else if (pathname.startsWith('/dashboard')) {
      title = 'Dashboard';
    } else if (pathname.startsWith('/management')) {
      title = 'Management';
    } else if (pathname.startsWith('/mobile')) {
      title = 'Mobile Secure';
    } else if (pathname.startsWith('/openscan')) {
      title = 'Supply Chain Security';
    } else if (pathname.startsWith('/web')) {
      title = 'Web Secure';
    }

    document.title = `${title ? `${title} | ` : ''}Data Theorem`;
  }, [pathname]);

  // Page Menu.
  // prettier-ignore
  const routesSidebarContent = [
    // TODO: This needs to be moved to a path that doesn't conflict with the api route.
    //       Maybe `/share`?
    //       This does *not* follow the router pathing matchers bc of this ^.
    ['/api/share',  null],
    ['/api',        MenuApi],
    ['/cloud',      MenuCloud],
    ['/dashboard',  MenuDashboard],
    // TODO: This needs to be moved to a path that doesn't conflict with the management route.
    //       Maybe `/share`?
    //       This does *not* follow the router pathing matches bc of this ^.
    ['/management/share', null],
    ['/management/onboarding', null],
    ['/management', MenuManagement],
    ['/mobile',     MenuMobile],
    ['/openscan',   MenuSupplyChainSecurity],
    ['/web',        MenuWeb],
  ];

  // Page Content.
  // prettier-ignore
  const routesPageContent = {
    // TODO: Support for stripping trailing slashes.
    //'.*/+$':                                             window.location.pathname.slice(0, -1),
    //
    // TODO: Support for client side Page authorization.
    //       Currently a subset of these pages will show a <CenteredCircularProgress />
    //       These pages need to be updated to show their corresponding Page Skeleton and not the
    //       Circular progress spinner.
    //
    '/api':                                               '/api/overview',
    '/api/inventory':                                     ApiInventoryPage,
    '/api/inventory/:currentTab':                         ApiInventoryPage,
    '/api/discover/inventory':                            '/api/inventory',
    '/api/discover/inventory/:currentTab':                '/api/inventory/:currentTab',
    '/api/asset-groups':                                  ApiAssetGroupsPage,
    '/api/asset-groups/:id':                              ApiAssetGroupsUuidPage,
    '/api/discover/configuration':                        '/management/configurations',
    '/api/policy':                                        '/api/policies',
    '/api/policy/:id':                                    '/api/policies/:id',
    '/api/policies':                                      ApiPoliciesPage,
    '/api/policies/:id':                                  '/api/policies/:id/api',
    '/api/policies/:id/:currentTab':                      ApiPoliciesUuidPage,

    // {{ TODO: Port over to Pages.
    '/api/inspect/hack-and-extract':                      accessControlsApi(ApolloHackExtractContainer),
    '/api/inspect/policy-violations':                     accessControlsApi(ApolloSecurityDashboardContainer),
    '/api/inspect/policy-violations/:id':                 accessControlsApi(ApolloPolicyViolationDetailsContainer),
    '/api/inspect/leaky-apis':                            accessControlsApi(ApolloLeakyApisContainer),
    '/api/inspect/detect-and-inject':                     accessControlsApi(ApolloDetectInjectContainer),
    '/api/inspect/correct-and-protect':                   accessControlsApi(ApolloCorrectAndProjectContainer),
    // NOTE:                                              DO NOT do this inlining - Legacy only.
    '/api/inspect/correct-and-protect/:id':               accessControlsApi(({ id }) => <ApolloPolicyRuleWithViolationsAndDetails id={id} is_eligible_for_auto_remediation={true} filter_by_violation_status={['OPEN']} />),
    '/api/inspect/policy-rules/:id':                      accessControlsApi(ApolloPolicyRuleWithViolationsAndDetails),
    // NOTE:                                              DO NOT do this inlining - Legacy only.
    '/api/discover/shadow-assets':                        accessControlsApi(ApolloShadowAssetsContainer),
    '/api/restful-apis/:id':                              accessControlsApi(ApolloRestfulApiDetailsContainer),
    '/api/restful-apis/:id/:currentTab':                  accessControlsApi(ApolloRestfulApiDetailsContainer),
    '/api/cloud-resources/:id':                           accessControlsApi(ApolloCloudResourceDetailsContainer),
    '/api/cloud-resources/:id/:currentTab':               accessControlsApi(ApolloCloudResourceDetailsContainer),
    '/api/network-services/:id':                          accessControlsApi(ApolloNetworkServiceDetailsContainer),
    '/api/network-services/:id/:currentTab':              accessControlsApi(ApolloNetworkServiceDetailsContainer),
    '/api/web-applications/:id':                          accessControlsApi(ApolloWebApplicationDetailsContainer),
    '/api/web-applications/:id/:currentTab':              accessControlsApi(ApolloWebApplicationDetailsContainer),
    '/api/api-operations/:id':                            accessControlsApi(ApolloApiOperationDetailsContainer),
    '/api/overview/detail':                               accessControlsApi(ApolloOverviewDetail),
    '/api/overview':                                      accessControlsApi(ApolloOverview),
    '/api/activity':                                      accessControlsApi(ApolloActivityDashboardContainer),
    '/api/protect':                                       accessControlsApi(ApolloApiProtectContainer),
    '/api/ssrf-sploits-toolkit':                          accessControlsApi(ApolloSsrfSploitsContainer),
    '/api/alerts-integration':                            accessControlsApi(ApolloAlertsIntegrations),
    '/api/shared-links':                                  accessControlsApi(ApolloSharedLinks),
    // }}

    '/cloud':                                             CloudPage,
    '/cloud/asm-setup':                                   AsmSetupPage,
    '/cloud/asset-groups':                                CloudAssetGroupsPage,
    '/cloud/activity':                                    CloudActivityPage,
    '/cloud/inventory':                                   CloudInventoryPage,
    '/cloud/inventory/:currentTab':                       CloudInventoryPage,
    '/cloud/cloud-resources/:id':                         CloudCloudResourcesUuidPage,
    '/cloud/cloud-resources/:id/:currentTab':             CloudCloudResourcesUuidPage,
    '/cloud/network-services/:id':                        CloudNetworkServicesUuidPage,
    '/cloud/network-services/:id/:currentTab':            CloudNetworkServicesUuidPage,
    '/cloud/policies':                                    CloudPoliciesPage,
    '/cloud/policies/:id':                                '/cloud/policies/:id/cloud',
    '/cloud/policies/:id/:currentTab':                    CloudPoliciesUuidPage,
    '/cloud/policy-violations':                           CloudPolicyViolationsPage,
    '/cloud/policy-violations/:policyViolationId':        CloudPolicyViolationsUuidPage,
    '/cloud/restful-apis/:id':                            CloudRestfulApisUuidPage,
    '/cloud/restful-apis/:id/:currentTab':                CloudRestfulApisUuidPage,
    "/cloud/security-toolkits":                           CloudToolkitsPage,
    "/cloud/security-toolkits/cloud-gotchas":             CloudToolkitsGotchasPage,
    "/cloud/security-toolkits/cloud-gotchas/:currentTab": CloudToolkitsGotchasPage,
    "/cloud/security-toolkits/cloud-leaks":               CloudToolkitsLeaksPage,
    '/cloud/security-toolkits/code-red':                  CloudToolkitsCodeRedPage,
    '/cloud/web-applications/:id':                        CloudWebApplicationsUuid,
    '/cloud/web-applications/:id/:currentTab':            CloudWebApplicationsUuid,
    '/cloud/asset-groups/:id':                            CloudAssetGroupsUuidPage,
    '/dashboard':                                         DashboardPage,

    // {{ TODO: Port over to Pages.
    //
    //    TODO: ApolloSharedApp needs to be moved with clear workflows for public facing pages.
    //          Potentially `/share`?
    //
    //    NOTE: These two 'onboarding' routes are subtly different.
    //          The '/api/share/:token/onboarding' is for cross product onboarding
    //          - Mobile Apps
    //          - Cloud Environments
    //          - Jira
    //          - Slack
    //          The '/api/share/:token/onboard' is the legacy implementation for onboarding
    //          - Cloud Resources
    //          - API Gateways
    '/api/share/:token/onboarding':                       '/management/share/:token/onboarding',
    '/management/share/:token/onboarding':                       accessControlsShare(ShareProductOnboardingWizardPublicView),
    '/api/share/:token/onboard':                          '/management/share/:token/onboard',
    '/management/share/:token/onboard':                          accessControlsShare(ShareOnboardingPublicView),
    '/api/share/:token/violations/:id':                   '/management/share/:token/violations/:id',
    '/management/share/:token/violations/:id':                   accessControlsShare(ShareViolationPublicView),
    '/api/share/:token/web-applications/:id':             '/management/share/:token/web-applications/:id',
    '/management/share/:token/web-applications/:id':             accessControlsShare(ShareWebAppPublicView),
    '/api/share/:token/web-applications/:id/:currentTab': '/management/share/:token/web-applications/:id/:currentTab',
    '/management/share/:token/web-applications/:id/:currentTab': accessControlsShare(ShareWebAppPublicView),
    '/api/share/:token/cloud-resources/:id':              '/management/share/:token/cloud-resources/:id',
    '/management/share/:token/cloud-resources/:id':              accessControlsShare(ShareCloudResourcePublicView),
    '/api/share/:token/cloud-resources/:id/:currentTab':  '/management/share/:token/cloud-resources/:id/:currentTab',
    '/management/share/:token/cloud-resources/:id/:currentTab':  accessControlsShare(ShareCloudResourcePublicView),
    '/api/share/:token/restful-apis/:id':                 '/management/share/:token/restful-apis/:id',
    '/management/share/:token/restful-apis/:id':                 accessControlsShare(ShareRestfulAPIPublicView),
    '/api/share/:token/restful-apis/:id/:currentTab':     '/management/share/:token/restful-apis/:id/:currentTab',
    '/management/share/:token/restful-apis/:id/:currentTab':     accessControlsShare(ShareRestfulAPIPublicView),
    '/api/share/:token/network-services/:id':             '/management/share/:token/network-services/:id',
    '/management/share/:token/network-services/:id':             accessControlsShare(ShareNetworkServicesPublicView),
    '/api/share/:token/network-services/:id/:currentTab': '/management/share/:token/network-services/:id/:currentTab',
    '/management/share/:token/network-services/:id/:currentTab': accessControlsShare(ShareNetworkServicesPublicView),
    // }}

    '/management':                                        '/management/products',
    '/management/configurations':                         ManagementConfigurationsPage,
    // {{ TODO: Port over to Pages.
    '/management/products':                               accessControlsLandingPage(ManagementLandingPage),
    '/management/users/invite':                           accessControlsApi(ManagementUserInvite),
    '/management/users/:userId':                          accessControlsApi(ManagementUserEdit),
    '/management/users':                                  accessControlsApi(ManagementUserDashboard),
    '/management/activity':                               accessControlsApi(ManagementActivity),
    '/management/profile':                                accessControlsApi(ManagementProfile),
    '/management/onboarding':                             accessControlsApi(ManagementProductOnboardingContainer),
    '/management/vendors':                                accessControlsApi(ManagementVendorManagementContainer),
    '/management/compliance-report':                      accessControlsApi(ManagementComplianceReports),
    // }}

    '/web/':                                              WebPage,
    '/web/asset-groups':                                  WebAssetGroupsPage,
    '/web/cloud-resources/:id':                           WebCloudResourcesUuidPage,
    '/web/cloud-resources/:id/:currentTab':               WebCloudResourcesUuidPage,
    '/web/graphql-apis/:id':                              WebGraphqlApisUuidPage,
    '/web/graphql-apis/:id/:currentTab':                  WebGraphqlApisUuidPage,
    '/web/inventory':                                     WebInventoryPage,
    '/web/inventory/:currentTab':                         WebInventoryPage,
    '/web/network-services/:id':                          WebNetworkServicesUuidPage,
    '/web/network-services/:id/:currentTab':              WebNetworkServicesUuidPage,
    '/web/asset-groups/:id':                              WebAssetGroupsUuidPage,
    '/web/policies':                                      WebPoliciesPage,
    '/web/policies/:id':                                  '/web/policies/:id/web',
    '/web/policies/:id/:currentTab':                      WebPoliciesUuidPage,
    '/web/policy-violations':                             WebPolicyViolationsPage,
    '/web/policy-violations/:violationId':                WebPolicyViolationsUuidPage,
    '/web/restful-apis/:id':                              WebRestfulApisUuidPage,
    '/web/restful-apis/:id/:currentTab':                  WebRestfulApisUuidPage,
    '/web/security-toolkits':                             WebSecurityToolkitsPage,
    '/web/security-toolkits/sql-injection/*':             WebSecurityToolkitsDetectInjectPage,
    '/web/security-toolkits/xss-attack':                  WebSecurityToolkitsXssAttackPage,
    '/web/security-toolkits/xss-attack/:currentTab':      WebSecurityToolkitsXssAttackPage,
    '/web/security-toolkits/xss-protection':              WebSecurityToolkitsXssProtectionPage,
    '/web/toxic-tokens/*':                                WebToxicTokensPage,
    '/web/web-applications/:id':                          WebApplicationsUuidPage,
    '/web/web-applications/:id/:currentTab':              WebApplicationsUuidPage,
  };

  // TODO: Should *not* be checking path outside of the router.
  const menu = routesSidebarContent.find(config => {
    return pathname.indexOf(config[0]) === 0;
  });

  return (
    <div className={styles.root}>
      {/* Sidebar */}
      <Router style={{ height: '100%' }} data-testid="pageMenu">
        {menu && menu[1] ? (
          <ConfigurableLHSMenu path={`${menu[0]}/*`} configuration={menu[1]} />
        ) : null}
      </Router>

      {/* Page */}
      <div className={styles.content} data-testid="pageContent">
        <Suspense fallback={null}>
          <OnboardingBanner />

          <Router style={{ height: '100%' }}>
            {entries(
              routesPageContent,
            ).map(
              (
                [route, Destination]: [
                  string,
                  string | React$AbstractComponent<{| +path: string |}, mixed>,
                ],
                i,
              ) =>
                typeof Destination === 'string' ? (
                  <Redirect key={i} from={route} to={Destination} noThrow />
                ) : (
                  <Destination key={i} path={route} />
                ),
            )}

            {/* TODO: Move to pages after apps are merged. */}
            <NotFoundPage default />
          </Router>
          <Notifier
            notifications={notifications}
            onDismissClick={dispatchDismissNotificationClicked}
          />
        </Suspense>
      </div>
    </div>
  );
}

export default memo<{||}>(ApplicationRouting);
