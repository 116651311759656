//@flow
import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  TextField,
  StepContent,
} from '@material-ui/core';
import { palette } from '@dt/theme';
import gcp_authenticators from '@dt/graphql-support/horizon/gcp_authenticators';
import Markdown from '../Markdown';
import { Message } from '../Message';
import { Dropzone } from '../Dropzone';
import { ConfigurationsCloudStepperNextPreviousStepActions } from './ConfigurationsCloudStepperNextPreviousStepActions';
import { ConfigurationsCloudStepperSuccessLabel } from './ConfigurationsCloudStepperSuccessLabel';
import { ConfigurationsCloudStepperSuccessMessage } from './ConfigurationsCloudStepperSuccessMessage';
import configurationsCloudStepperGcpMarkdownStep1 from './ConfigurationsCloudStepperGcpMarkdownStep1';
import configurationsCloudStepperGcpMarkdownStep2 from './ConfigurationsCloudStepperGcpMarkdownStep2';
import configurationsCloudStepperGcpMarkdownStep3 from './ConfigurationsCloudStepperGcpMarkdownStep3';
import configurationsCloudStepperGcpMarkdownStep4 from './ConfigurationsCloudStepperGcpMarkdownStep4';

import type { ElementRef } from 'react';
import type {
  GcpAuthenticatorsCreateMutation,
  GcpAuthenticatorsCreateMutationVariables,
} from '@dt/graphql-support/types/GcpAuthenticatorsCreateMutation';

import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +onComplete: () => void,
|};

const ORGANIZATION_ID_REGEX = /^[1-9]\d*$/; // no leading 0's

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add a GCP authenticator.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperGcpComponent = function ConfigurationsCloudStepperGcp({
  onComplete,
}: Props) {
  // Stepper state.
  const [stepIndex, setStepIndex] = useState(0);

  // Form state.
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const dropzoneRef = useRef<null | ElementRef<typeof Dropzone>>(null);
  const [organizationId, setOrganizationId] = useState('');
  const [file, setFile] = useState<null | File>(null);
  const [serviceAccount, setServiceAccount] = useState<null | JSON>(null);
  const [jsonFileError, setJsonFileError] = useState<null | string>(null);

  // Form validation.
  const isOrganizationIdValidRequired = organizationId.length > 0;
  const isOrganizationIdValidRegex = ORGANIZATION_ID_REGEX.test(organizationId);
  const isFileValid =
    file !== null && serviceAccount !== null && jsonFileError === null;

  // GCP authenticator create mutation.
  const [
    createGcpAuthenticator,
    {
      loading: createGcpAuthenticatorLoading,
      error: createGcpAuthenticatorError,
    },
  ] = useMutation<
    GcpAuthenticatorsCreateMutation,
    GcpAuthenticatorsCreateMutationVariables,
  >(gcp_authenticators.create);

  // Stepper event handlers.
  const handleOnClickNext = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };
  const handleOnClickPrev = () => {
    setStepIndex(stepIndex => {
      if (stepIndex > 0) {
        return stepIndex - 1;
      }
      return stepIndex;
    });
  };

  // Form event handlers.
  const handleChangeOrganizationId = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ) => {
    const value = (e.target: HTMLInputElement).value.trim();
    setOrganizationId(value);
  };
  const handleOnDropFile = (file: File) => {
    const reader = new FileReader();

    const validateJsonContents = json => {
      if (
        typeof json.auth_provider_x509_cert_url === 'string' &&
        typeof json.auth_uri === 'string' &&
        typeof json.client_email === 'string' &&
        typeof json.client_id === 'string' &&
        typeof json.client_x509_cert_url === 'string' &&
        typeof json.private_key === 'string' &&
        typeof json.private_key_id === 'string' &&
        typeof json.project_id === 'string' &&
        typeof json.token_uri === 'string' &&
        typeof json.type === 'string'
      )
        return true;
      return false;
    };

    const handleFileRead = () => {
      const content = reader.result;

      let validJson: boolean;
      let fileContent: null | JSON = null;

      if (typeof content === 'string') {
        try {
          fileContent = JSON.parse(content);
          validJson = validateJsonContents(fileContent);
        } catch (error) {
          validJson = false;
        }
      } else {
        validJson = false;
      }

      setFile(file);
      setServiceAccount(validJson ? fileContent : null);
      setJsonFileError(
        validJson
          ? null
          : `The uploaded file doesn't seem to be valid... Please upload a Service Account JSON file`,
      );
    };
    reader.onloadend = handleFileRead;
    reader.readAsText(file);
  };
  const handleOnSubmitForm = async () => {
    setIsSubmitted(true);

    if (
      isOrganizationIdValidRequired &&
      isOrganizationIdValidRegex &&
      isFileValid
    ) {
      const { data, errors } = await createGcpAuthenticator({
        variables: {
          body: {
            organization_id: organizationId,
            service_account: serviceAccount,
          },
        },
      });

      // Continue to next step if successfull.
      if (data && !errors) {
        setStepIndex(stepIndex => stepIndex + 1);
      }
    }
  };

  return (
    <section
      style={{
        width: '100%',
        margin: '0,auto',
        padding: 16,
      }}
    >
      {/* Header */}
      <Typography variant="body1">
        A video tutorial describing the GCP onboarding process is available{' '}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://drive.google.com/file/d/1VnAnzzjNQhKOz6U7w60dRjl3sQdFUPYX/view"
        >
          here
        </a>
        .
      </Typography>

      <Stepper
        activeStep={stepIndex}
        style={{ margin: '0, auto' }}
        orientation="vertical"
      >
        {/* Step 1 */}
        <Step>
          <StepLabel>Create new GCP project</StepLabel>
          <StepContent aria-label="Step 1">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperGcpMarkdownStep1} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevExclude
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <StepLabel>Create service account in the project</StepLabel>
          <StepContent aria-label="Step 2">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperGcpMarkdownStep2} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <StepLabel>Add service account to your organization</StepLabel>
          <StepContent aria-label="Step 3">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperGcpMarkdownStep3} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 4 */}
        <Step>
          <StepLabel>Submit Organization ID and Service Account Key</StepLabel>
          <StepContent aria-label="Step 4">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperGcpMarkdownStep4} />
            </div>

            <div style={{ marginBottom: 16 }}>
              {createGcpAuthenticatorError && (
                <Message
                  variant="error"
                  message={createGcpAuthenticatorError.message}
                />
              )}

              <TextField
                variant="outlined"
                label="Organization ID"
                id="OrganizationIDInput"
                required
                error={
                  isSubmitted &&
                  (!isOrganizationIdValidRequired ||
                    !isOrganizationIdValidRegex)
                }
                helperText={
                  !isSubmitted
                    ? null
                    : isOrganizationIdValidRequired
                    ? isOrganizationIdValidRegex
                      ? null
                      : 'Organization ID must be a number without leading zeroes'
                    : 'Please enter an Organization ID'
                }
                autoFocus
                value={organizationId}
                onChange={handleChangeOrganizationId}
                style={{ display: 'block', marginBottom: 12 }}
                disabled={createGcpAuthenticatorLoading}
              />
              <Dropzone
                ariaLabel="Service Account JSON Dropzone"
                dragActiveText="Drop your Service Account JSON file to upload it"
                onDropFile={handleOnDropFile}
                ref={dropzoneRef}
              >
                <div
                  style={{
                    border: '2px dotted black',
                    height: 200,
                    width: '100%',
                    textAlign: 'center',
                    paddingTop: 85,
                  }}
                >
                  {file ? (
                    jsonFileError ? (
                      <span style={{ color: palette.red30 }}>
                        Selected {file.name}. {jsonFileError}
                      </span>
                    ) : (
                      `Selected ${file.name}. This is a valid Service Account JSON file`
                    )
                  ) : (
                    <span
                      style={{
                        color:
                          isSubmitted && !isFileValid ? palette.red30 : 'unset',
                      }}
                    >
                      Click to browse or drag and drop a Service Account JSON
                      file
                    </span>
                  )}
                </div>
              </Dropzone>
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={() => {
                handleOnSubmitForm();
              }}
              nextLabel={
                createGcpAuthenticatorLoading
                  ? 'Testing...'
                  : createGcpAuthenticatorError
                  ? 'Resubmit'
                  : 'Submit'
              }
              nextDisabled={
                (isSubmitted &&
                  (!isOrganizationIdValidRequired ||
                    !isOrganizationIdValidRegex ||
                    !isFileValid)) ||
                createGcpAuthenticatorLoading
              }
              nextIsLoading={createGcpAuthenticatorLoading}
              prevDisabled={createGcpAuthenticatorLoading}
            />
          </StepContent>
        </Step>

        {/* Step 5 */}
        <Step>
          <ConfigurationsCloudStepperSuccessLabel
            step={5}
            stepIndex={stepIndex}
          />
          <StepContent aria-label="Step 5">
            <div style={{ lineHeight: '160%' }}>
              <ConfigurationsCloudStepperSuccessMessage
                variant={CloudAuthenticatorTypeValues.GoogleCloudPlatform}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={onComplete}
              nextLabel="Close"
            />
          </StepContent>
        </Step>
      </Stepper>
    </section>
  );
};

export const ConfigurationsCloudStepperGcp = ConfigurationsCloudStepperGcpComponent;
