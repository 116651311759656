// @flow
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { palette } from '@dt/theme';
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Link } from '@reach/router';
import network_services from '@dt/graphql-support/horizon/network_services';

import type {
  NetworkServicesCreateMutation,
  NetworkServicesCreateMutationVariables,
  NetworkServicesCreateMutation_network_services_create,
} from '@dt/graphql-support/types/NetworkServicesCreateMutation';

const DomainNameTldRegex = /\.[A-Za-z]{2,6}$/;

/*
 * Network service factory that wraps the gql `createNetworkService` mutation with additional requests.
 */
const useNetworkServicesCreate = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | {| +message: string |}>(null);
  const [data, setData] = useState<null | {|
    +network_services: $ReadOnlyArray<NetworkServicesCreateMutation_network_services_create>,
  |}>(null);

  // Network service create mutation.
  const [createNetworkService] = useMutation<
    NetworkServicesCreateMutation,
    NetworkServicesCreateMutationVariables,
  >(network_services.create);

  return [
    /*
     * Wrapper around `createNetworkService`.
     *
     * Creates two network services - one for HTTP and one for HTTPS.
     */
    async (domainName: string) => {
      setLoading(true);

      // Request both HTTP and HTTPS ports for a domain.
      const results = await Promise.all([
        createNetworkService({
          variables: {
            body: {
              domain_name: domainName,
              port: 80,
              is_tls_encrypted: false,
              application_layer_protocol: 'HTTP',
            },
          },
        }),
        createNetworkService({
          variables: {
            body: {
              domain_name: domainName,
              port: 443,
              is_tls_encrypted: true,
              application_layer_protocol: 'HTTP',
            },
          },
        }),
      ]);

      // Return results, errors or otherwise.
      if (results[0].errors || results[1].errors) {
        setError({
          message:
            'An error occurred creating the network services.\nThis does not seem to be a valid domain.\nPlease try again or contact support@datatheorem.com',
        });
        setData(null);
      } else {
        setError(null);
        const network_service_1 = results[0].data?.network_services_create;
        const network_service_2 = results[1].data?.network_services_create;
        setData({
          network_services: [
            ...(network_service_1 ? [network_service_1] : []),
            ...(network_service_2 ? [network_service_2] : []),
          ],
        });
      }

      setLoading(false);
    },
    {
      loading,
      error,
      data,
    },
  ];
};

/*
 * Allows the user to add network services manually.
 */
const NetworkServicesManualAddComponent = function NetworkServicesManualAdd() {
  const [domainName, setDomainNameInputValue] = useState<string>('');

  // Create network services.
  const [
    createdNetworkServices,
    {
      loading: createNetworkServiceLoading,
      error: createNetworkServiceError,
      data: createNetworkServiceData,
    },
  ] = useNetworkServicesCreate();

  // Form validation.
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const isValidDomainNameRequired = domainName.length > 0;
  const isValidDomainNameRegex = DomainNameTldRegex.test(domainName);

  // Form Event Handlers.
  // Set input to domain name if a url was provided.
  const handleOnBlur = () => {
    setDomainNameInputValue(domainName => {
      const matches = domainName.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
      return (matches && matches[1]) || domainName;
    });
  };
  const handleOnSubmit = async () => {
    setIsSubmitted(true);
    if (isValidDomainNameRegex && isValidDomainNameRequired) {
      await createdNetworkServices(domainName);
    }
  };

  return (
    <>
      <Typography>Enter a Web URL to add it to your inventory.</Typography>
      <Box
        display="flex"
        flexWrap="nowrap"
        mb={1}
        component="form"
        onSubmit={(e: SyntheticMouseEvent<HTMLElement>) => {
          e.preventDefault();
          handleOnSubmit();
        }}
      >
        <TextField
          label="Web URL"
          id="DomainName"
          margin="dense"
          variant="outlined"
          fullWidth
          autoFocus
          value={domainName}
          error={
            isSubmitted &&
            (!isValidDomainNameRequired || !isValidDomainNameRegex)
          }
          helperText={
            !isSubmitted
              ? null
              : isValidDomainNameRequired
              ? isValidDomainNameRegex
                ? null
                : 'This does not seem to be a valid domain. Make sure to include the top-level domain (ie ".com", ".org", etc).'
              : 'Please enter a domain.'
          }
          onChange={e => setDomainNameInputValue(e.target.value)}
          onBlur={handleOnBlur}
        />

        <Box ml={1}>
          <Button
            type="submit"
            style={{ minWidth: 90, height: 40, marginTop: 8 }}
            variant="contained"
            color="primary"
            size="large"
            aria-label="Add"
            disabled={isSubmitted && !isValidDomainNameRegex}
          >
            Add
          </Button>
        </Box>
      </Box>
      <div style={{ marginBottom: 20 }} />
      {createNetworkServiceLoading && <CircularProgress />}
      {createNetworkServiceError && (
        <div
          style={{
            padding: 20,
            background: palette.red50,
            color: palette.red10,
          }}
        >
          <Typography>{createNetworkServiceError.message}</Typography>
        </div>
      )}
      {createNetworkServiceData && (
        <Typography>
          {createNetworkServiceData.network_services.length}{' '}
          {createNetworkServiceData.network_services.length === 1
            ? 'domain'
            : 'domains'}{' '}
          discovered
        </Typography>
      )}
      {createNetworkServiceData &&
        createNetworkServiceData.network_services.map(newNetworkService => (
          <div
            key={newNetworkService.id}
            style={{
              padding: 20,
              background: palette.green50,
              color: palette.green10,
            }}
          >
            <Typography>
              Network service {newNetworkService.url} [PORT:
              {newNetworkService.port}] successfully added.{' '}
              <Link
                to={`/api/network-services/${newNetworkService.id}`}
                style={{
                  textDecoration: 'underline',
                  color: palette.blue20,
                }}
              >
                Click here
              </Link>{' '}
              to view details.
            </Typography>
          </div>
        ))}
      {createNetworkServiceData && (
        <Typography>
          For analysis purposes, Data Theorem tracks ports of a Web URL
          separately. In this case,{' '}
          {createNetworkServiceData.network_services.length}{' '}
          {createNetworkServiceData.network_services.length === 1
            ? 'port'
            : 'ports'}{' '}
          were found.
        </Typography>
      )}
    </>
  );
};

export const NetworkServicesManualAdd = NetworkServicesManualAddComponent;
