// @flow
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  TextField,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from '@material-ui/core';
import azure_authenticators from '@dt/graphql-support/horizon/azure_authenticators';
import Markdown from '../Markdown';
import { Message } from '../Message';
import { ConfigurationsCloudStepperNextPreviousStepActions } from './ConfigurationsCloudStepperNextPreviousStepActions';
import { ConfigurationsCloudStepperSuccessLabel } from './ConfigurationsCloudStepperSuccessLabel';
import { ConfigurationsCloudStepperSuccessMessage } from './ConfigurationsCloudStepperSuccessMessage';
import configurationsCloudStepperAzureMarkdownStep1 from './ConfigurationsCloudStepperAzureMarkdownStep1';
import configurationsCloudStepperAzureMarkdownStep2 from './ConfigurationsCloudStepperAzureMarkdownStep2';
import configurationsCloudStepperAzureMarkdownStep3 from './ConfigurationsCloudStepperAzureMarkdownStep3';

import type {
  AzureAuthenticatorsCreateMutation,
  AzureAuthenticatorsCreateMutationVariables,
} from '@dt/graphql-support/types/AzureAuthenticatorsCreateMutation';

import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +onComplete: () => void,
|};

// UUID 4 regex: https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
const UUID4_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add a Azure authenticator.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperAzureComponent = function ConfigurationsCloudStepperAzure({
  onComplete,
}: Props) {
  // Stepper state.
  const [stepIndex, setStepIndex] = useState(0);

  // Form state.
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [directoryId, setDirectoryId] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [authKey, setAuthKey] = useState('');

  // Form validation.
  const isValidDirectoryIdRequired = directoryId.length > 0;
  const isValidDirectoryIdRegex = directoryId.match(UUID4_REGEX);
  const isValidApplicationIdRequired = applicationId.length > 0;
  const isValidApplicationIdRegex = applicationId.match(UUID4_REGEX);
  const isValidAuthKeyRequired = authKey.length > 0;

  // Azure authenticator create mutation.
  const [
    createAzureAuthenticator,
    {
      loading: createAzureAuthenticatorLoading,
      error: createAzureAuthenticatorError,
    },
  ] = useMutation<
    AzureAuthenticatorsCreateMutation,
    AzureAuthenticatorsCreateMutationVariables,
  >(azure_authenticators.create);

  // Stepper event handlers.
  const handleOnClickNext = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };
  const handleOnClickPrev = () => {
    setStepIndex(stepIndex => {
      if (stepIndex > 0) {
        return stepIndex - 1;
      }
      return stepIndex;
    });
  };

  // Form event handlers.
  const handleOnChangeDirectoryId = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ): void => {
    const value = (e.target: HTMLInputElement).value;

    setDirectoryId(value);
  };
  const handleOnChangeApplicationId = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ): void => {
    const value = (e.target: HTMLInputElement).value;

    setApplicationId(value);
  };
  const handleOnChangeAuthKey = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ): void => {
    const value = (e.target: HTMLInputElement).value;

    setAuthKey(value);
  };
  const handleOnSubmitForm = async () => {
    setIsSubmitted(true);

    if (
      isValidDirectoryIdRequired &&
      isValidDirectoryIdRegex &&
      isValidApplicationIdRequired &&
      isValidApplicationIdRegex &&
      isValidAuthKeyRequired
    ) {
      const { data, errors } = await createAzureAuthenticator({
        variables: {
          body: {
            tenant_id: directoryId,
            client_id: applicationId,
            client_secret: authKey,
          },
        },
      });

      // Continue to next step if successfull.
      if (data && !errors) {
        setStepIndex(stepIndex => stepIndex + 1);
      }
    }
  };

  return (
    <section
      style={{
        width: '100%',
        margin: '0,auto',
        padding: 16,
      }}
    >
      <Stepper
        activeStep={stepIndex}
        style={{ margin: '0, auto' }}
        orientation="vertical"
      >
        {/* Step 1 */}
        <Step>
          <StepLabel>Create new Azure AD Application</StepLabel>
          <StepContent aria-label="Step 1">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperAzureMarkdownStep1} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevExclude
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <StepLabel>Assign Reader Role to AD Application</StepLabel>
          <StepContent aria-label="Step 2">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperAzureMarkdownStep2} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <StepLabel>Submit Application Credential</StepLabel>
          <StepContent aria-label="Step 3">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperAzureMarkdownStep3} />
            </div>

            <form style={{ marginBottom: 16 }}>
              {createAzureAuthenticatorError && (
                <Message
                  variant="error"
                  message={createAzureAuthenticatorError.message}
                />
              )}

              <TextField
                id="directoryId"
                variant="outlined"
                label="Directory ID"
                error={
                  isSubmitted &&
                  (!isValidDirectoryIdRequired || !isValidDirectoryIdRegex)
                }
                helperText={
                  !isSubmitted
                    ? null
                    : isValidDirectoryIdRequired
                    ? isValidDirectoryIdRegex
                      ? null
                      : 'Directory ID should be in uuid4 format, 8-4-4-4-12 (hex digits)'
                    : 'Please enter a Directory ID'
                }
                autoFocus
                value={directoryId}
                onChange={handleOnChangeDirectoryId}
                fullWidth
                style={{ display: 'block', marginBottom: 12 }}
              />
              <TextField
                id="applicationId"
                variant="outlined"
                label="Application ID"
                error={
                  isSubmitted &&
                  (!isValidApplicationIdRequired || !isValidApplicationIdRegex)
                }
                helperText={
                  !isSubmitted
                    ? null
                    : isValidApplicationIdRequired
                    ? isValidApplicationIdRegex
                      ? null
                      : 'Application ID should be in uuid4 format, 8-4-4-4-12 (hex digits)'
                    : 'Please enter an Application ID'
                }
                value={applicationId}
                fullWidth
                onChange={handleOnChangeApplicationId}
                style={{ display: 'block', marginBottom: 12 }}
              />
              <TextField
                id="authorizationKey"
                variant="outlined"
                label="Authorization Key"
                error={isSubmitted && !isValidAuthKeyRequired}
                helperText={
                  !isSubmitted
                    ? null
                    : isValidAuthKeyRequired
                    ? null
                    : 'Please enter an Authorization Key'
                }
                value={authKey}
                fullWidth
                onChange={handleOnChangeAuthKey}
                style={{ display: 'block', marginBottom: 12 }}
              />
            </form>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={() => {
                handleOnSubmitForm();
              }}
              nextLabel={
                createAzureAuthenticatorLoading
                  ? 'Testing...'
                  : createAzureAuthenticatorError
                  ? 'Resubmit'
                  : 'Submit'
              }
              nextDisabled={
                (isSubmitted &&
                  (!isValidDirectoryIdRequired ||
                    !isValidApplicationIdRequired ||
                    !isValidAuthKeyRequired)) ||
                createAzureAuthenticatorLoading
              }
              nextIsLoading={createAzureAuthenticatorLoading}
              prevDisabled={createAzureAuthenticatorLoading}
            />
          </StepContent>
        </Step>

        {/* Step 4 */}
        <Step>
          <ConfigurationsCloudStepperSuccessLabel
            step={4}
            stepIndex={stepIndex}
          />
          <StepContent aria-label="Step 4">
            <div style={{ lineHeight: '160%' }}>
              <ConfigurationsCloudStepperSuccessMessage
                variant={CloudAuthenticatorTypeValues.Azure}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={onComplete}
              nextLabel="Close"
            />
          </StepContent>
        </Step>
      </Stepper>
    </section>
  );
};

export const ConfigurationsCloudStepperAzure = ConfigurationsCloudStepperAzureComponent;
