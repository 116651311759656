//@flow
import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { palette } from '@dt/theme';
import { useQuery } from '@dt/apollo-link-schema-rest';
import cloud_authenticators from '@dt/graphql-support/horizon/cloud_authenticators';
import ExtLink from './../ExtLink';
import TagDiscoveredVia from './../TagDiscoveredVia';
import { ConfigurationsCloudAvatar } from './ConfigurationsCloudAvatar';

import type { DiscoveredViaType } from '@dt/horizon-api';
import type { CloudAuthenticatorsListQuery } from '@dt/graphql-support/types';

type Props = {|
  +authenticator_id: ?string,
  +discovered_via: DiscoveredViaType,
|};

/*
 * Shows the user where an asset was discovered with.
 * When no authenticator is present the user is shown how the asset was discovered.
 *
 * @param authenticator_id - The authenticator id from the backend
 * @param discovered_via - How the asset was discovered
 */
const ConfigurationsCloudAuthenticatorsSectionComponent = function ConfigurationsCloudAuthenticatorsSection({
  authenticator_id,
  discovered_via,
}: Props) {
  const { data, loading, fetchMore } = useQuery<
    CloudAuthenticatorsListQuery,
    _,
  >(cloud_authenticators.list);

  useEffect(() => {
    fetchMore && fetchMore();
  }, [fetchMore]);

  const authenticators =
    data &&
    data.cloud_authenticators_list.cloud_authenticators.length > 0 &&
    data.cloud_authenticators_list.cloud_authenticators
      .filter(Boolean)
      .filter(({ id }) => id === authenticator_id || '');

  return (
    <>
      <Typography gutterBottom variant="h6">
        Discovered With
      </Typography>

      {/* Render the specific authenticator, if the id isn't provided how the asset was discovered. */}
      {loading ? (
        <Skeleton animation="pulse" variant="text" height={40} width="100%" />
      ) : !authenticators ? (
        <TagDiscoveredVia discovered_via={discovered_via} />
      ) : (
        authenticators.map(({ id, authenticator_type }) => (
          <ExtLink to={`/management/configurations`} key={id}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ConfigurationsCloudAvatar
                variant={authenticator_type}
                style={{ marginRight: 12 }}
              />

              <Typography
                style={{
                  textTransform: 'uppercase',
                  color: palette.green20,
                  backgroundColor: palette.green50,
                  borderRadius: 2,
                  padding: '2px 4px',
                }}
                variant="caption"
              >
                online
              </Typography>
            </div>
          </ExtLink>
        ))
      )}
    </>
  );
};

export const ConfigurationsCloudAuthenticatorsSection = ConfigurationsCloudAuthenticatorsSectionComponent;
