// @flow
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Stepper,
  Step,
  StepLabel,
  TextField,
  StepContent,
} from '@material-ui/core';
import { palette } from '@dt/theme';
import aws_authenticators from '@dt/graphql-support/horizon/aws_authenticators';
import Markdown from '../Markdown';
import { Message } from '../Message';
import { ConfigurationsCloudStepperNextPreviousStepActions } from './ConfigurationsCloudStepperNextPreviousStepActions';
import { ConfigurationsCloudStepperSuccessLabel } from './ConfigurationsCloudStepperSuccessLabel';
import { ConfigurationsCloudStepperSuccessMessage } from './ConfigurationsCloudStepperSuccessMessage';
import configurationsCloudStepperAwsMarkdownStep1 from './ConfigurationsCloudStepperAwsMarkdownStep1';
import configurationsCloudStepperAwsMarkdownStep2 from './ConfigurationsCloudStepperAwsMarkdownStep2';

import type {
  AwsAuthenticatorsCreateMutation,
  AwsAuthenticatorsCreateMutationVariables,
} from '@dt/graphql-support/types/AwsAuthenticatorsCreateMutation';

import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +onComplete: () => void,
|};

// The external ID does not need strong randomness and is not a secret
// More information: https://aws.amazon.com/blogs/security/how-to-use-external-id-when-granting-access-to-your-aws-resources/.
const generateExternalId = () => {
  let cryptoObj = window.crypto || window.msCrypto;
  const abc = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  let token = '';
  const typedArray = new Uint8Array(32);
  cryptoObj.getRandomValues(typedArray);

  for (let i = 0; i < typedArray.length; i++) {
    token += abc[typedArray[i] % 36];
  }
  return token;
};
const sessionGeneratedExternalId = generateExternalId();

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add a Aws authenticator.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperAwsComponent = function ConfigurationsCloudStepperAws({
  onComplete,
}: Props) {
  // Stepper state.
  const [stepIndex, setStepIndex] = useState(0);

  // Form state.
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [externalId, setExternalId] = useState(sessionGeneratedExternalId);
  const [roleArn, setRoleArn] = useState('');
  const [externalIdEditable, setExternalIdEditable] = useState(false);

  // Form validation.
  const isValidRoleArnRequired = roleArn.length > 0;
  const isValidExternalIdRequired = externalId.length > 0;

  // Aws authenticator create mutation.
  const [
    createAwsAuthenticator,
    {
      loading: createAwsAuthenticatorLoading,
      error: createAwsAuthenticatorError,
    },
  ] = useMutation<
    AwsAuthenticatorsCreateMutation,
    AwsAuthenticatorsCreateMutationVariables,
  >(aws_authenticators.create);

  // Stepper event handlers.
  const handleOnClickNext = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };
  const handleOnClickPrev = () => {
    setStepIndex(stepIndex => {
      if (stepIndex > 0) {
        return stepIndex - 1;
      }
      return stepIndex;
    });
  };

  // Form event handlers.
  const handleOnChangeRoleArn = (e: SyntheticInputEvent<HTMLInputElement>) => {
    let value = (e.target: HTMLInputElement).value;

    setRoleArn(value);
  };
  const handleOnToggleExternalIdEditable = () => {
    // If we are switching "editable" off, we need to put the sessionGeneratedExternalId back in state
    if (externalIdEditable) {
      setExternalIdEditable(false);
      setExternalId(sessionGeneratedExternalId);
    } else {
      setExternalIdEditable(true);
    }
  };
  const handleOnChangeExternalId = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ) => {
    if (!externalIdEditable) return;

    let value = (e.target: HTMLInputElement).value;
    setExternalId(value);
  };
  const handleOnSubmitForm = async () => {
    setIsSubmitted(true);

    if (isValidRoleArnRequired && isValidExternalIdRequired) {
      const { data, errors } = await createAwsAuthenticator({
        variables: {
          body: {
            role_arn: roleArn,
            external_id: externalId,
          },
        },
      });

      // Continue to next step if successfull.
      if (data && !errors) {
        setStepIndex(stepIndex => stepIndex + 1);
      }
    }
  };

  return (
    <section
      style={{
        width: '100%',
        margin: '0,auto',
        padding: 16,
      }}
    >
      <Stepper
        activeStep={stepIndex}
        style={{ margin: '0 auto' }}
        orientation="vertical"
      >
        {/* Step 1 */}
        <Step>
          <StepLabel>Create AWS Policy</StepLabel>
          <StepContent aria-label="Step 1">
            <div style={{ lineHeight: '160%' }}>
              <p>
                {"Don't"} have access to AWS?{' '}
                <a href="/users/invite">Click here</a> to invite an AWS admin
                from your organization to the Data Theorem portal so they can
                complete the onboarding.
              </p>
              <Markdown text={configurationsCloudStepperAwsMarkdownStep1} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevExclude
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <StepLabel>Create AWS Role</StepLabel>
          <StepContent aria-label="Step 2">
            <div style={{ lineHeight: '160%' }}>
              <Markdown
                text={configurationsCloudStepperAwsMarkdownStep2(externalId)}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <StepLabel>Connect to DataTheorem</StepLabel>
          <StepContent aria-label="Step 3">
            <div style={{ lineHeight: '160%' }}>
              For our service to be able to connect to your AWS account, please
              type in below your <b>Role ARN</b> of your recently created Role
            </div>

            <div style={{ marginBottom: 16 }}>
              {createAwsAuthenticatorError && (
                <Message
                  variant="error"
                  message={createAwsAuthenticatorError.message}
                />
              )}

              <TextField
                label="Role ARN"
                id="RoleARNInput"
                margin="dense"
                required
                variant={'outlined'}
                error={isSubmitted && !isValidRoleArnRequired}
                helperText={
                  !isSubmitted
                    ? null
                    : isValidRoleArnRequired
                    ? null
                    : 'Please input a Role ARN'
                }
                fullWidth
                autoFocus
                value={roleArn}
                disabled={createAwsAuthenticatorLoading}
                onChange={handleOnChangeRoleArn}
              />
            </div>
            <TextField
              fullWidth
              variant={'outlined'}
              margin="dense"
              label="External ID"
              error={isSubmitted && !isValidExternalIdRequired}
              helperText={
                <>
                  {!externalIdEditable
                    ? 'Generated randomly based on your session. Only valid for this session.'
                    : isValidExternalIdRequired
                    ? 'Override is only for advanced users! Do not use unless you have a known External ID used during Role ARN creation.'
                    : 'Please input an External ID'}
                  <span
                    style={{ cursor: 'pointer', color: palette.brand20 }}
                    onClick={handleOnToggleExternalIdEditable}
                  >
                    {' '}
                    {externalIdEditable
                      ? 'Use Suggested External ID'
                      : 'Override'}
                  </span>
                </>
              }
              onChange={handleOnChangeExternalId}
              value={externalId}
              disabled={!externalIdEditable}
            />

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={() => {
                handleOnSubmitForm();
              }}
              nextLabel={
                createAwsAuthenticatorLoading
                  ? 'Testing...'
                  : createAwsAuthenticatorError
                  ? 'Resubmit'
                  : 'Submit'
              }
              nextDisabled={
                (isSubmitted &&
                  (!isValidExternalIdRequired || !isValidRoleArnRequired)) ||
                createAwsAuthenticatorLoading
              }
              nextIsLoading={createAwsAuthenticatorLoading}
              prevDisabled={createAwsAuthenticatorLoading}
            />
          </StepContent>
        </Step>

        {/* Step 4 */}
        <Step>
          <ConfigurationsCloudStepperSuccessLabel
            step={4}
            stepIndex={stepIndex}
          />
          <StepContent aria-label="Step 4">
            <div style={{ lineHeight: '160%' }}>
              <ConfigurationsCloudStepperSuccessMessage
                variant={CloudAuthenticatorTypeValues.AmazonWebServices}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevExclude
              prevOnClick={handleOnClickPrev}
              nextOnClick={onComplete}
              nextLabel="Close"
            />
          </StepContent>
        </Step>
      </Stepper>
    </section>
  );
};

export const ConfigurationsCloudStepperAws = ConfigurationsCloudStepperAwsComponent;
