// @flow
import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/client';
import {
  Grid,
  TextField,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from '@material-ui/core';
import { palette } from '@dt/theme';
import axway_authenticators from '@dt/graphql-support/horizon/axway_authenticators';
import { Dropzone } from '../Dropzone';
import Markdown from '../Markdown';
import { Message } from '../Message';
import { ConfigurationsCloudStepperNextPreviousStepActions } from './ConfigurationsCloudStepperNextPreviousStepActions';
import { ConfigurationsCloudStepperSuccessLabel } from './ConfigurationsCloudStepperSuccessLabel';
import { ConfigurationsCloudStepperSuccessMessage } from './ConfigurationsCloudStepperSuccessMessage';
import configurationsCloudStepperAxwayMarkdownStep1 from './ConfigurationsCloudStepperAxwayMarkdownStep1';
import configurationsCloudStepperAxwayMarkdownStep2 from './ConfigurationsCloudStepperAxwayMarkdownStep2';
import configurationsCloudStepperAxwayMarkdownStep3 from './ConfigurationsCloudStepperAxwayMarkdownStep3';
import configurationsCloudStepperAxwayMarkdownStep4 from './ConfigurationsCloudStepperAxwayMarkdownStep4';

import type { ElementRef } from 'react';
import type {
  AxwayAuthenticatorsCreateMutation,
  AxwayAuthenticatorsCreateMutationVariables,
} from '@dt/graphql-support/types/AxwayAuthenticatorsCreateMutation';

import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +onComplete: () => void,
|};

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add a Axway authenticator.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperAxwayComponent = function ConfigurationsCloudStepperAxway({
  onComplete,
}: Props) {
  // Stepper state.
  const [stepIndex, setStepIndex] = useState(0);

  // Form state.
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const dropzoneRef = useRef<null | ElementRef<typeof Dropzone>>(null);
  const [clientId, setClientId] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [privateKey, setPrivateKey] = useState('');
  const [file, setFile] = useState(null);

  // Form validation.
  const isValidFileType = !!file && file.type === 'application/x-x509-ca-cert';
  const isValidClientIdRequired = clientId.length > 0;
  const isValidOrganizationIdRequired = organizationId.length > 0;

  // Axway authenticator create mutation.
  const [
    createAxwayAuthenticator,
    {
      loading: createAxwayAuthenticatorLoading,
      error: createAxwayAuthenticatorError,
    },
  ] = useMutation<
    AxwayAuthenticatorsCreateMutation,
    AxwayAuthenticatorsCreateMutationVariables,
  >(axway_authenticators.create);

  // Stepper event handlers.
  const handleOnClickNext = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };
  const handleOnClickPrev = () => {
    setStepIndex(stepIndex => {
      if (stepIndex > 0) {
        return stepIndex - 1;
      }
      return stepIndex;
    });
  };

  // Form event handlers.
  const handleOnChangeClientId = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const value = (e.target: HTMLInputElement).value.trim();
    setClientId(value);
  };
  const handleOnChangeOrganizationId = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ) => {
    const value = (e.target: HTMLInputElement).value.trim();
    setOrganizationId(value);
  };
  const handleOnDropFile = (file: File) => {
    const reader = new FileReader();
    setFile(file);

    const handleFileRead = () => {
      const content = reader.result;

      if (typeof content === 'string') {
        setPrivateKey(content);
      }
    };
    reader.onloadend = handleFileRead;
    reader.readAsText(file);
  };
  const handleOnSubmitForm = async () => {
    setIsSubmitted(true);

    if (
      isValidFileType &&
      isValidClientIdRequired &&
      isValidOrganizationIdRequired
    ) {
      const { data, errors } = await createAxwayAuthenticator({
        variables: {
          body: {
            client_id: clientId,
            organization_id: organizationId,
            private_key: privateKey,
          },
        },
      });

      // Continue to next step if successfull.
      if (data && !errors) {
        setStepIndex(stepIndex => stepIndex + 1);
      }
    }
  };

  return (
    <section
      style={{
        width: '100%',
        margin: '0,auto',
        padding: 16,
      }}
    >
      <Stepper
        activeStep={stepIndex}
        style={{ margin: '0, auto' }}
        orientation="vertical"
      >
        {/* Step 1 */}
        <Step>
          <StepLabel>Prerequisite</StepLabel>
          <StepContent aria-label="Step 1">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperAxwayMarkdownStep1} />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevExclude
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <StepLabel>Generate an Asymmetric Key Pair</StepLabel>
          <StepContent aria-label="Step 2">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperAxwayMarkdownStep2} />
            </div>

            <ConfigurationsCloudStepperAxwayDropzone
              dropzoneRef={dropzoneRef}
              file={file}
              onDropFile={handleOnDropFile}
            />

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <StepLabel>Add DataTheorem service account</StepLabel>
          <StepContent aria-label="Step 3">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperAxwayMarkdownStep3} />
            </div>

            <TextField
              id="clientId"
              label="Client ID"
              name="Client ID"
              variant="outlined"
              error={isSubmitted && !isValidClientIdRequired}
              helperText={
                !isSubmitted
                  ? null
                  : isValidClientIdRequired
                  ? null
                  : 'Please enter a Client ID'
              }
              fullWidth
              onChange={handleOnChangeClientId}
              value={clientId}
            />

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 4 */}
        <Step>
          <StepLabel>Obtain your organization ID</StepLabel>
          <StepContent aria-label="Step 4">
            <div style={{ lineHeight: '160%' }}>
              <Markdown text={configurationsCloudStepperAxwayMarkdownStep4} />
            </div>

            <Grid container direction="column" spacing={2}>
              {createAxwayAuthenticatorError && (
                <Grid item style={{ margin: 8 }}>
                  <Message
                    variant="error"
                    message={createAxwayAuthenticatorError.message}
                  />
                </Grid>
              )}

              <Grid item>
                <ConfigurationsCloudStepperAxwayDropzone
                  dropzoneRef={dropzoneRef}
                  file={file}
                  onDropFile={handleOnDropFile}
                />
              </Grid>

              <Grid item>
                <TextField
                  id="clientId"
                  label="Client ID"
                  name="Client ID"
                  variant="outlined"
                  error={isSubmitted && !isValidClientIdRequired}
                  helperText={
                    !isSubmitted
                      ? null
                      : isValidClientIdRequired
                      ? null
                      : 'Please enter a Client ID'
                  }
                  fullWidth
                  onChange={handleOnChangeClientId}
                  value={clientId}
                />
              </Grid>

              <Grid item>
                <TextField
                  id="organizationId"
                  label="Organization ID"
                  name="Organization ID"
                  variant="outlined"
                  error={isSubmitted && !isValidOrganizationIdRequired}
                  helperText={
                    !isSubmitted
                      ? null
                      : isValidOrganizationIdRequired
                      ? null
                      : 'Please enter a Organization ID'
                  }
                  fullWidth
                  onChange={handleOnChangeOrganizationId}
                  value={organizationId}
                />
              </Grid>

              <Grid item>
                <ConfigurationsCloudStepperNextPreviousStepActions
                  prevOnClick={handleOnClickPrev}
                  nextOnClick={() => {
                    handleOnSubmitForm();
                  }}
                  nextLabel={
                    createAxwayAuthenticatorLoading
                      ? 'Testing...'
                      : createAxwayAuthenticatorError
                      ? 'Resubmit'
                      : 'Submit'
                  }
                  nextDisabled={
                    (isSubmitted &&
                      (!isValidFileType ||
                        !isValidClientIdRequired ||
                        !isValidOrganizationIdRequired)) ||
                    createAxwayAuthenticatorLoading
                  }
                  nextIsLoading={createAxwayAuthenticatorLoading}
                  prevDisabled={createAxwayAuthenticatorLoading}
                />
              </Grid>
            </Grid>
          </StepContent>
        </Step>

        {/* Step 5 */}
        <Step>
          <ConfigurationsCloudStepperSuccessLabel
            step={5}
            stepIndex={stepIndex}
          />
          <StepContent aria-label="Step 5">
            <div style={{ lineHeight: '160%' }}>
              <ConfigurationsCloudStepperSuccessMessage
                variant={CloudAuthenticatorTypeValues.Axway}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={onComplete}
              nextLabel="Close"
            />
          </StepContent>
        </Step>
      </Stepper>
    </section>
  );
};

const ConfigurationsCloudStepperAxwayDropzone = function ConfigurationsCloudStepperAxwayDropzone({
  dropzoneRef,
  onDropFile,
  file,
}: {|
  +dropzoneRef: {| current: null | ElementRef<typeof Dropzone> |},
  +onDropFile: (file: File) => void,
  +file: null | File,
|}) {
  return (
    <Dropzone
      ariaLabel="Private Key File Dropzone"
      onDropFile={onDropFile}
      dragActiveText="Drop your Private Key file to upload it"
      ref={dropzoneRef}
    >
      <div
        style={{
          border: '2px dotted black',
          height: 200,
          width: '100%',
          textAlign: 'center',
          paddingTop: 85,
        }}
      >
        {file ? (
          file.type === 'application/x-x509-ca-cert' ? (
            `Selected ${file.name}.`
          ) : (
            <span style={{ color: palette.red30 }}>
              {file.name} is not a valid key file
            </span>
          )
        ) : (
          'Click to browse or drag and drop your Private Key file'
        )}
      </div>
    </Dropzone>
  );
};

export const ConfigurationsCloudStepperAxway = ConfigurationsCloudStepperAxwayComponent;
