// @flow
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  TextField,
  Step,
  Stepper,
  StepLabel,
  StepContent,
} from '@material-ui/core';
import mulesoft_authenticators from '@dt/graphql-support/horizon/mulesoft_authenticators';
import Markdown from '../Markdown';
import { Message } from '../Message';
import { ConfigurationsCloudStepperNextPreviousStepActions } from './ConfigurationsCloudStepperNextPreviousStepActions';
import { ConfigurationsCloudStepperSuccessLabel } from './ConfigurationsCloudStepperSuccessLabel';
import { ConfigurationsCloudStepperSuccessMessage } from './ConfigurationsCloudStepperSuccessMessage';
import configurationsCloudStepperMulesoftMarkdownStep1 from './ConfigurationsCloudStepperMulesoftMarkdownStep1';
import configurationsCloudStepperMulesoftMarkdownStep2 from './ConfigurationsCloudStepperMulesoftMarkdownStep2';
import configurationsCloudStepperMulesoftMarkdownStep3 from './ConfigurationsCloudStepperMulesoftMarkdownStep3';

import type {
  MulesoftAuthenticatorsCreateMutation,
  MulesoftAuthenticatorsCreateMutationVariables,
} from '@dt/graphql-support/types/MulesoftAuthenticatorsCreateMutation';

import { CloudAuthenticatorTypeValues } from '@dt/graphql-support/types';

type Props = {|
  +onComplete: () => void,
|};

// UUID 4 regex: https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
const UUID4_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

/*
 * NOTE: Use the `ConfigurationsCloudStepper` facade component.
 *
 * Allows the user to add a Mulesoft authenticator.
 *
 * @param onComplete - Triggered event when the user completes the stepper
 */
const ConfigurationsCloudStepperMulesoftComponent = function ConfigurationsCloudStepperMulesoft({
  onComplete,
}: Props) {
  // Stepper state.
  const [stepIndex, setStepIndex] = useState(0);

  // Form state.
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [organizationId, setOrganizationId] = useState('');
  const [accountUsername, setAccountUsername] = useState('');
  const [accountPassword, setAccountPassword] = useState('');

  // Form validation.
  const isValidOrganizationIdRequired = organizationId.length > 0;
  const isValidOrganizationIdRegex = organizationId.match(UUID4_REGEX);
  const isValidAccountPasswordRequired = accountPassword.length > 0;
  const isValidAccountUsernameRequired = accountUsername.length > 0;

  // Mulesoft authenticator create mutation.
  const [
    createMulesoftAuthenticator,
    {
      loading: createMulesoftAuthenticatorLoading,
      error: createMulesoftAuthenticatorError,
    },
  ] = useMutation<
    MulesoftAuthenticatorsCreateMutation,
    MulesoftAuthenticatorsCreateMutationVariables,
  >(mulesoft_authenticators.create);

  // Stepper event handlers.
  const handleOnClickNext = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };
  const handleOnClickPrev = () => {
    setStepIndex(stepIndex => {
      if (stepIndex > 0) {
        return stepIndex - 1;
      }
      return stepIndex;
    });
  };

  // Form event handlers.
  const handleOnChangeOrganizationId = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ): void => {
    const value = (e.target: HTMLInputElement).value;

    setOrganizationId(value);
  };
  const handleOnChangeAccountUsername = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ): void => {
    const value = (e.target: HTMLInputElement).value;

    setAccountUsername(value);
  };
  const handleOnChangeAccountPassword = (
    e: SyntheticInputEvent<HTMLInputElement>,
  ): void => {
    const value = (e.target: HTMLInputElement).value;

    setAccountPassword(value);
  };
  const handleOnSubmitForm = async () => {
    setIsSubmitted(true);

    if (
      isValidOrganizationIdRequired &&
      isValidOrganizationIdRegex &&
      isValidAccountPasswordRequired &&
      isValidAccountUsernameRequired
    ) {
      const { data, errors } = await createMulesoftAuthenticator({
        variables: {
          body: {
            organization_id: organizationId,
            account_password: accountPassword,
            account_username: accountUsername,
          },
        },
      });

      // Continue to next step if successfull.
      if (data && !errors) {
        setStepIndex(stepIndex => stepIndex + 1);
      }
    }
  };

  return (
    <section
      style={{
        width: '100%',
        margin: '0,auto',
        padding: 16,
      }}
    >
      <Stepper
        activeStep={stepIndex}
        style={{ margin: '0, auto' }}
        orientation="vertical"
      >
        {/* Step 1 */}
        <Step>
          <StepLabel>Create a custom MuleSoft Role</StepLabel>
          <StepContent aria-label="Step 1">
            <div style={{ lineHeight: '160%' }}>
              <Markdown
                text={configurationsCloudStepperMulesoftMarkdownStep1}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevExclude
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 2 */}
        <Step>
          <StepLabel>Assign Permissions to Custom Role</StepLabel>
          <StepContent aria-label="Step 2">
            <div style={{ lineHeight: '160%' }}>
              <Markdown
                text={configurationsCloudStepperMulesoftMarkdownStep2}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={handleOnClickNext}
            />
          </StepContent>
        </Step>

        {/* Step 3 */}
        <Step>
          <StepLabel>Creating a MuleSoft Analyzer Account</StepLabel>
          <StepContent aria-label="Step 3">
            <div style={{ lineHeight: '160%' }}>
              <Markdown
                text={configurationsCloudStepperMulesoftMarkdownStep3}
              />
            </div>

            <div style={{ marginBottom: 16 }}>
              {createMulesoftAuthenticatorError && (
                <Message
                  variant="error"
                  message={createMulesoftAuthenticatorError.message}
                />
              )}

              <TextField
                id="Organization-ID"
                label="Organization ID"
                variant="outlined"
                error={
                  isSubmitted &&
                  (!isValidOrganizationIdRequired ||
                    !isValidOrganizationIdRegex)
                }
                helperText={
                  !isSubmitted
                    ? null
                    : isValidOrganizationIdRequired
                    ? isValidOrganizationIdRegex
                      ? null
                      : 'Organization ID should be in uuid4 format, 8-4-4-4-12 (hex digits)'
                    : 'Please enter a Organization ID'
                }
                autoFocus
                fullWidth
                value={organizationId}
                onChange={handleOnChangeOrganizationId}
                style={{ display: 'block', marginBottom: 12 }}
              />
              <TextField
                id="Account-Username"
                label="Account Username"
                variant="outlined"
                error={isSubmitted && !isValidAccountUsernameRequired}
                helperText={
                  !isSubmitted
                    ? null
                    : isValidAccountUsernameRequired
                    ? null
                    : 'Please enter an Account Username'
                }
                fullWidth
                value={accountUsername}
                onChange={handleOnChangeAccountUsername}
                style={{ display: 'block', marginBottom: 12 }}
              />
              <TextField
                id="Account-Password"
                type="password"
                label="Account Password"
                variant="outlined"
                error={isSubmitted && !isValidAccountPasswordRequired}
                helperText={
                  !isSubmitted
                    ? null
                    : isValidAccountPasswordRequired
                    ? null
                    : 'Please enter an Account Password'
                }
                fullWidth
                value={accountPassword}
                onChange={handleOnChangeAccountPassword}
                style={{ display: 'block', marginBottom: 12 }}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={() => {
                handleOnSubmitForm();
              }}
              nextLabel={
                createMulesoftAuthenticatorLoading
                  ? 'Testing...'
                  : createMulesoftAuthenticatorError
                  ? 'Resubmit'
                  : 'Submit'
              }
              nextDisabled={
                (isSubmitted &&
                  (!isValidOrganizationIdRequired ||
                    !isValidOrganizationIdRegex ||
                    !isValidAccountUsernameRequired ||
                    !isValidAccountPasswordRequired)) ||
                createMulesoftAuthenticatorLoading
              }
              nextIsLoading={createMulesoftAuthenticatorLoading}
              prevDisabled={createMulesoftAuthenticatorLoading}
            />
          </StepContent>
        </Step>

        {/* Step 4 */}
        <Step>
          <ConfigurationsCloudStepperSuccessLabel
            step={4}
            stepIndex={stepIndex}
          />
          <StepContent aria-label="Step 4">
            <div style={{ lineHeight: '160%' }}>
              <ConfigurationsCloudStepperSuccessMessage
                variant={CloudAuthenticatorTypeValues.Mulesoft}
              />
            </div>

            <ConfigurationsCloudStepperNextPreviousStepActions
              prevOnClick={handleOnClickPrev}
              nextOnClick={onComplete}
              nextLabel="Close"
            />
          </StepContent>
        </Step>
      </Stepper>
    </section>
  );
};

export const ConfigurationsCloudStepperMulesoft = ConfigurationsCloudStepperMulesoftComponent;
